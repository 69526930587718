/*
Component to display a list of categories.
Allows users to select and delete categories, enhancing user interaction within the chat application.
*/
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  MenuList,
  Separator,
  Window,
  WindowContent,
  WindowHeader,
} from "react95";
import { handleDeletes3 } from "../utils/s3crud";
import { updateLinks } from "../state/actions";

function LinksList() {
  const dispatch = useDispatch();
  const sharedLinks = useSelector((state) => state.sharedLinks);

  const [loadedLinks, setLoadedLinks] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    setLoadedLinks(sharedLinks);
  }, [sharedLinks]);

  const handleCheckDelete = () => {
    setDeleting(true);
  };

  const handleDenyDelete = () => {
    setDeleting(false);
    setConfirmDelete(false);
  };

  const handleDeleteConfirmed = async (filename) => {
    setConfirmDelete(true);
    await handleDeleteLink(filename);
    setDeleting(false);
  };

  const handleDeleteLink = async (filename, id) => {
    await handleDeletes3(filename);
    const newLinks = loadedLinks.filter(link => link.id !== id);
    dispatch(updateLinks(newLinks));
    setLoadedLinks(newLinks);
  };

  if (loadedLinks.length === 0 ?? sharedLinks.length === 0) {
    return (
      <WindowContent className="notice-div">
        <b>Please create new links to share in the chat interface!</b>
      </WindowContent>
    );
  }
  return (
    <Window>
      <WindowContent>
        <MenuList>
          {loadedLinks.map((linkData, index) => (
            linkData?.link &&
            <div style={{flexDirection: 'row', display: 'flow', }}>
              <div>
                <p>&emsp;<a href={linkData.link}>view link</a></p>
                <p><b>Created</b>: {linkData.created}</p>
                <p><b>Description</b>: {linkData.description}</p>
                <p>
                  <b>Link</b>: {linkData.link}
                </p>
              </div>
              <div>
                <Button onClick={(e) => handleCheckDelete()}>Delete</Button>
                {deleting && !confirmDelete && (
                <div
                  style={{
                    zIndex: 9,
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <Window>
                    <WindowHeader
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        //   padding: "5px 10px", // Adjust padding as needed
                      }}
                    >
                      <span>Confirm Deletion</span>
                      <Button onClick={() => handleDenyDelete()}>❌</Button>
                    </WindowHeader>
                    <WindowContent>
                      <p>
                        <br />
                        <b>
                          🧙 This method will delete the link and associated
                          chat from our database.
                        </b>
                      </p>
                      <Button onClick={() => handleDeleteConfirmed(linkData.filename, linkData.id)}>
                        Confirm
                      </Button>
                    </WindowContent>
                  </Window>
                </div>
              )}
              </div>
              <br />
              <Separator />
            </div>
          ))}
        </MenuList>
      </WindowContent>
    </Window>
  );
}
export default LinksList;
