export default function PublicNotice() {
  return (
    <div>
      <h1>Public Notices</h1>
        <div>
        <h2>09/17/24</h2>
        <p>
          - Added option to enable / disable image creations in the chat requests. Users can turn on and off at will. Default is set to on.
        </p>
        <p>
          - Updated UI to include a <i>Study</i> tab holding the <i>Ideas</i>, <i>Topics</i> and more to come.
        </p>
        <p>
          - Added <i>Links</i> tab containing shared links list to display all the links you created. Added the ability to delete a shared link.
        </p>
        </div>
      <div>
      <h2>
          <b>09/13/24</b>
        </h2>
        <p>- Terms of service will change to save all chat data effective immediately. Users will need to enter their own API key to prevent their chat data being saved. All chat history data created without a personal API key will be automatically saved by default and considered property of gpt-tutor.me, et al. Users will be able to upgrade their accounts to 'Private' instances by using their own API key, resulting in no chat history data being saved.</p>
      </div>
      <div>
      <h2>
          <b>09/12/24</b>
        </h2>
        <p>
          - Updated Error page UI.
        </p>
        <p>
          - Enhanced encryption for sensitive information stored in localstorage using AES.
        </p>
        <p>
          - Added Retry button to retry intro messages or last responses.
        </p>
        <p>
          - Finished share functionality components. Live testing to beginning.
        </p>
        <p>
          - Added Current Settings to the top of the chat interface for easy reference.
        </p>
        <p>
          - Bugs fixed
        </p>
        <p>
          Image generation requests missing model name on first load. Added init useEffect to load default model.
        </p>
        <p>
          User Settings failed to send on intro chat and used default settings with 'Explain it like im 5', resulting in childish intros. Ensured active user settings are loaded and used.
        </p>
        <p>
          Handled chat error handling to revert last chat message sent if err.
        </p>
      </div>
      <div>
        <h2>
          <b>09/11/24</b>
        </h2>
        <p>
          - The Terms of Service has been updated to include the CreativeML Open
          RAIL M license.
        </p>
        <p>
          - New tab layout to separate user interface settings and application
          info. Added Public Notices tab.
        </p>
        <p>
          - Updated settings to include 'Biography' section. Users can enter a
          personalized description that will be inserted into the system propmt
          of chat requests to help steer personalized inference outputs.
        </p>
        <p>
          - Public Share component created with the ability to share selected
          chats using <s>gun.eco</s> AWS on a private database.
          <br />
          Soon to be rolled out, users can click a button to share their current
          selected chat.
          <br />
          The chat will be saved and the user will be given a URL that they can share with
          others. When another user visits the shared URL the saved message
          will be retrieved and presented for display.
          <br /> <i>Note</i>I was going to setup a gun.eco relay for decentralized communications,
          but it is really unnecessary for this application and is not free to host anymore. 
          Opted for AWS s3 storage, which the gun relay would use on the backend anyway.
        </p>
        <p>
          - Updated chat interface UI with continue button to auto-continue the
          chat topic response. Added message clearing to bottom chat interface.
        </p>
        <p>
          - Added chat interface buttons to handle last message deletion, image
          generations, chat continuations and clearing messages.
        </p>
        <p>
          - Added image model selection in the settings menu. You can choose
          between the Schnell (open-licensed usage) model, or the Dev
          (non-commercial licensed usage) model.
        </p>
      </div>
    </div>
  );
}
