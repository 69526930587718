import { Separator, WindowContent } from "react95";

export const AppInfoTab = () => {
  return (
    <WindowContent>
      <div>
        <h1>Notices</h1>
        <i>
          This application is for entertainment/ informational / educational purposes only and
          not to be construed as a consultation or an advice giving service or
          professional replacement of any kind. Trust, but verify.
          <br />
          - Chat-GPT, GPT-4o and other models may supply factually inaccurate
          information about people, places or things in a convincing manner.
        </i>
        <p>
          <a href="https://openai.com/enterprise-privacy/">
            - OpenAI gives notice
          </a>{" "}
          to all enterprise users of their API Platform (that's us!) that they,
          "do not train on your business data [sic](data from ChatGPT Team,
          ChatGPT Enterprise, or our API Platform)", therefore, no chat data is
          saved or recorded to the best of our knowledge in any way on this site
          other than your local storage.
          <br />
          <br />- All data is saved locally using localstorage first. Confidential
          information, such as an API key, is encrypted using AES encryption. We
          record all chat data used with the System API key anonymously in a
          private database, which means that the chat requsts and responses are
          recorded, but no personally identifying information is recorded.
          <br />
          When sharing chats, the chat history of selected conversations is recorded into a private AWS
          database, but no personal identifying information is recorded.
          <br />
          <br />- To avoid having your chat data saved in our databse,
          you must supply your own API key. We cache your supplied API key in
          localstorage using{" "}
          <a href="https://www.geeksforgeeks.org/advanced-encryption-standard-aes/">
            AES encryption
          </a>
          .
          <br />
          <br />- Flux.1 Schnell/Dev image generation models are available to
          use through the Huggingface endpoint. You must abide by the license of
          the models you choose to use. You have the option to disable the image generation services at any time.
          <br />
          The Flux.1-schnell model has an{" "}
          <a href="https://github.com/black-forest-labs/flux/blob/main/model_licenses/LICENSE-FLUX1-schnell">
            Apache License
          </a>
          . The Flux.1-dev has{" "}
          <a href="https://github.com/black-forest-labs/flux/blob/main/model_licenses/LICENSE-FLUX1-dev">
            a non-commercial license
          </a>
          .
        </p>
        <Separator />
        <h2>Terms of Service</h2>
        <p>
          By using the gpt-tutor.me web-application, you agree to indemnify and
          hold harmless the developers and associated parties from any claims,
          liabilities, damages, or expenses arising out of your use of the
          application. This includes, but is not limited to, any misuse, data
          loss, or unauthorized access to your information while using the
          application.
        </p>
        <h3>Acceptance of Terms</h3>
        <p>
          By accessing and using the gpt-tutor.me web-application, you agree to
          comply with and be bound by these Terms of Service. If you do not
          agree with any part of the terms, you must not use the application.
        </p>
        <h3>Creative Rail-ML License adoption</h3>
        <p>
          Usage: CreativeML Open RAIL-M Use Restrictions You agree not to use
          this application or derivatives of the application:
          <br />
          - In any way that violates any applicable national, federal, state,
          local or international law or regulation;
          <br />
          - For the purpose of exploiting, harming or attempting to exploit or
          harm minors in any way;
          <br />
          - To generate or disseminate verifiably false information and/or
          content with the purpose of harming others;
          <br />
          - To generate or disseminate personal identifiable information that
          can be used to harm an individual;
          <br />
          - To defame, disparage or otherwise harass others;
          <br />
          - For fully automated decision making that adversely impacts an
          individual’s legal rights or otherwise creates or modifies a binding,
          enforceable obligation;
          <br />
          - For any use intended to or which has the effect of discriminating
          against or harming individuals or groups based on online or offline
          social behavior or known or predicted personal or personality
          characteristics;
          <br />
          - To exploit any of the vulnerabilities of a specific group of persons
          based on their age, social, physical or mental characteristics, in
          order to materially distort the behavior of a person pertaining to
          that group in a manner that causes or is likely to cause that person
          or another person physical or psychological harm;
          <br />
          - For any use intended to or which has the effect of discriminating
          against individuals or groups based on legally protected
          characteristics or categories;
          <br />
          - To provide medical advice and medical results interpretation;
          <br />
          - To generate or disseminate information for the purpose to be used
          for administration of justice, law enforcement, immigration or asylum
          processes, such as predicting an individual will commit fraud/crime
          commitment (e.g. by text profiling, drawing causal relationships
          between assertions made in documents, indiscriminate and
          arbitrarily-targeted use).
          <br />
        </p>
        <h3>User Responsibilities</h3>
        <p>
          1. <b>Compliance with Laws</b>: Users must comply with all applicable
          laws and regulations while using the application.
          <br />
          2. <b>Account Security</b>: You are responsible for maintaining the
          confidentiality of your account information and for all activities
          that occur under your account.
          <br />
          3. <b>Prohibited Activities</b>: Users are prohibited from using the
          application for any unlawful activities, distributing harmful content,
          or attempting to disrupt the application's functionality.
          <br />
          4. <b>Reverse Engineering</b>: Users are strictly prohibited from
          reverse engineering, decompiling, or disassembling the source code of
          the application, including any attempt to derive the source code
          through the browser or other means.
        </p>
        <h3>Limitation of Liability</h3>
          <p>
          The developers shall not be liable for any direct, indirect,
          incidental, special, or consequential damages resulting from the use
          or inability to use the application. This includes, but is not limited
          to, damages for loss of profits, data, or other intangible losses.
          </p>
          <h3>Modifications to the Service</h3>
          <p>
            We reserve the right to modify or discontinue the application,
            temporarily or permanently, with or without notice. We are not
            liable to you or any third party for any modification, suspension,
            or discontinuation of the application.
          </p>
          <h3>Governing Law</h3>
          <p>
            These Terms of Service shall be governed by and construed in
            accordance with the laws of the jurisdiction in which the developers
            operate, (Florida, USA), without regard to its conflict of law
            principles.
          </p>
          <h3>Contact Information</h3>
          <p>
            For any questions or concerns regarding these terms, please contact
            our support team.
            <br />
            <br />
            <b>
              <i>
                By using this application, you acknowledge that you have read,
                understood, and agree to be bound by these terms.
              </i>
            </b>
          </p>
      </div>
    </WindowContent>
  );
};
