import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setAPIKey, setNotificationState } from "../state/actions";
import { useEffect, useState } from "react";

const useAPI = () => {
  const stateApiKey = useSelector(
    (state) => state.apiKey,
    (prevApiKey, nextApiKey) => prevApiKey === nextApiKey
  );
  const [apiKey, setNewApiKey] = useState(stateApiKey)
  const dispatch = useDispatch();

  useEffect(()=> {
    setNewApiKey(stateApiKey);
  }, [stateApiKey]);

  const updateApiKey = (newApiKey) => {
    dispatch(setAPIKey(newApiKey));
  };

  const sendChatRequest = async (
    messages,
    temperature = 0.7,
    maxTokens = 1024
  ) => {
    if (!apiKey) {
      console.error(
        "Error sending chat request:\nPlease provide a valid OpenAI API key"
      );
      dispatch(
        setNotificationState({
          level: "error",
          duration: 10,
          message: "Please provide a valid OpenAI API key.",
        })
      );
      return null;
    }
    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-4o-mini",
          messages: messages,
          max_tokens: maxTokens,
          n: 1,
          stop: null,
          temperature,
        },
        {
          headers: { Authorization: `Bearer ${apiKey}` },
        }
      );
      return response.data.choices[0].message.content.trim();
    } catch (error) {
      console.error("Error sending chat request:", error);
      console.log("---> IS YOUR OPENAI API KEY SET?");
      dispatch(
        setNotificationState({
          level: "error",
          duration: 10,
          message: `Error sending chat request: ${error}`,
        })
      );
    }
  };

  const convertChatMessagesToString = (chatMessages) => {
    return chatMessages
      .map((message) => `${message.role}: ${message.content}`)
      .join("\n");
  };
  const formatTopicTemplate = () => {
    return `Suggest 5 concise conversation topics related to the user's question in a single line bullet point format. Ensure each topic references the user's input as a standalone topic.`;
  };
  const formatChatTemplate = (
    topic,
    category,
    responseLength,
    readabilityScore,
    speakerStyle,
    useImageModel,
    biography = ""
  ) => {
    const chatStyles = {
      "explain-like-im-five": "Explain it like I'm five",
      "explain-like-professor": "Explain it like I'm a college professor",
      'friendly': "Explain it in a friendly style",
      'formal': "Explain it in a formal style",
      'technical': "Explain it in a technical style",
      'wizard': "Explain it like a magical wizard"
    };
    const imagePrompt = useImageModel ? "You have the option to include relevant images by wrapping an extremely detailed image prompt in <IMAGE_PROMPT> tokens, like this: <IMAGE_PROMPT>prompt</IMAGE_PROMPT>. The image prompts must be extremely precise and descriptive, yet restricted to a family friendly audience. Ensure that you avoid any usage of nudity, gross violence, harmful acts or illegal activities. Include an image when appropriate to enhance the learning experience.\n" : ""
    const biographyString =
      biography.length > 0
        ? `\nHere is a self written bio of the user you are helping: ${biography}\n`
        : "";
    return `You are a helpful and expert instructor on the topic of '${topic}', in the category of '${category}'.${biographyString}\n
If you don't know the answer, respond with 'Hmm, I don't know.'. You must refuse to speak about illegal or dangerous activities.\n${imagePrompt}
Respond in very expressive markdown format with an introductory description to the topic while keeping the response length around ${responseLength} words and aiming for a readability score of ${readabilityScore} on the Flesh-Kincaid readability scale. Use a speaking style like: ${chatStyles[speakerStyle]}.`;
};

  return {
    updateApiKey,
    sendChatRequest,
    convertChatMessagesToString,
    formatTopicTemplate,
    formatChatTemplate,
  };
};

export default useAPI;
