import { S3Client, PutObjectCommand, GetObjectCommand, DeleteObjectCommand } from '@aws-sdk/client-s3';


// Configure AWS S3 Client
const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  },
});

const bucketName = process.env.REACT_APP_BUCKET;

export const handleFileUploads3 = async (file, fileName) => {
  if (!file) return;
  const isImage = file && file.type.startsWith('image/');

  const params = {
    Bucket: bucketName,
    Key: fileName,
    Body: file,
    ContentType: isImage ? file.type : 'text/plain',
  };

  try {
    await s3Client.send(new PutObjectCommand(params));
  } catch (error) {
    console.error('Error uploading file:', error);
  }
};

export const handleb64ImageUploads3 = async (base64Image) => {
  const buffer = Buffer.from(base64Image, 'base64');
  const params = {
    Bucket: bucketName,
    Key: `images/${Date.now()}.png`,
    Body: buffer,
    ContentEncoding: 'base64',
    ContentType: 'image/png',
  };

  try {
    const { Key } = await s3Client.send(new PutObjectCommand(params));
    return Key;
  } catch (error) {
    console.error('Error uploading image to S3:', error);
    return null;
  }
};

export const fetchImageWKeyFromS3 = async (s3Key) => {
  const params = {
    Bucket: bucketName,
    Key: s3Key,
  };
  try {
    const data = await s3Client.send(new GetObjectCommand(params));
    const body = await data.Body.transformToString('base64');
    return `data:image/png;base64,${body}`;
  } catch (error) {
    console.error('Error fetching image from S3:', error);
    return null;
  }
};

/**
 * 
 * @param {*} textInput string
 * @param {*} fileName You need to supply the file ending, ie .txt
 */
export const handleTextUploads3 = async (textInput, fileName) => {
  const params = {
    Bucket: bucketName,
    Key: fileName,
    Body: textInput,
    ContentType: 'text/plain',
  };

  try {
    await s3Client.send(new PutObjectCommand(params));
    return true;
  } catch (error) {
    console.error('Error uploading file:', error);
    return false;
  }
};

export const handleDownloads3 = async (fileName) => {
  const params = {
    Bucket: bucketName,
    Key: fileName,
  };
  try {
    const data = await s3Client.send(new GetObjectCommand(params));
    const response = await data.Body.transformToString();
    return response
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};

export const handleDeletes3 = async (fileName) => {
  const params = {
    Bucket: bucketName,
    Key: fileName,
  };

  try {
    await s3Client.send(new DeleteObjectCommand(params));
    alert('File deleted successfully');
  } catch (error) {
    console.error('Error deleting file:', error);
  }
};