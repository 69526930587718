export const setImageModel = (state) => ({
  type: 'SET_IMAGE_MODEL',
  payload: state
});
export const setThinkingState = (state) => ({
  type: 'SET_THINKING_STATE',
  payload: state,
});
export const setLoadingState = (state) => ({
  type: 'SET_LOADING_STATE',
  payload: state,
});
export const setNotificationState = (notification) => ({
  type: 'SET_NOTIFICATION_STATE',
  payload: notification,
});
export const setCategoryKey = (categoryKey) => ({
  type: 'SET_CATEGORY_KEY',
  payload: categoryKey,
});
export const setCategories = (categories) => ({
  type: 'SET_CATEGORIES',
  payload: categories,
});
export const selectCategory = (category) => ({
  type: 'SELECT_CATEGORY',
  payload: category,
});
export const deleteCategory = (category) => ({
  type: 'DELETE_CATEGORY',
  payload: category,
});
export const clearCategories = () => ({
  type: 'CLEAR_CATEGORIES'
});
export const setTopics = (topics) => ({
  type: 'SET_TOPICS',
  payload: topics,
});
export const selectTopic = (topic) => ({
  type: 'SELECT_TOPIC',
  payload: topic,
});
export const clearTopics = () => ({
  type: 'CLEAR_TOPICS'
});
export const setBooks = (books) => ({
  type: 'SET_BOOKS',
  payload: books,
});
export const selectBook = (book) => ({
  type: 'SELECT_BOOK',
  payload: book,
});
export const setChatMessage = (message) => ({
  type: 'SET_CHAT_MESSAGE',
  payload: message,
});
export const addChatMessage = (message) => ({
  type: 'ADD_CHAT_MESSAGE',
  payload: message,
});
export const removeLastMessage = () => ({
  type: 'REMOVE_LAST_MESSAGE'
});
export const removeChatMessage = () => ({
  type: 'REMOVE_CHAT_MESSAGE'
});
export const clearMessages = () => ({
  type: 'CLEAR_MESSAGES',
});
export const resetConversation = () => ({
  type: 'RESET_CONVERSATION',
});
export const setBiography = (bio) => ({
  type: 'SET_BIOGRAPHY',
  payload: bio,
});
export const setResponseLength = (length) => ({
  type: 'SET_RESPONSE_LENGTH',
  payload: length,
});
export const setReadabilityScore = (score) => ({
  type: 'SET_READABILITY_SCORE',
  payload: score,
});
export const setSpeakerStyle = (style) => ({
  type: 'SET_SPEAKER_STYLE',
  payload: style,
});
export const setAPIKey = (apiKey) => ({
  type: 'SET_API_KEY',
  payload: apiKey,
});
export const setPrivacy = (privacy) => ({
  type: 'SET_PRIVACY',
  payload: privacy,
});
export const setUseImageModel = (useIt) => ({
  type: 'USE_IMAGE_MODEL',
  payload: useIt,
});
export const setSharedLinks = (link) => ({
  type: 'SET_LINK',
  payload: link,
});
export const updateLinks = (links) => ({
  type: 'UPDATE_LINKS',
  payload: links,
});
