/*
Entry point for the React application. Sets up Redux store, loads persisted state, and renders the main App component.
*/
import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { configureStore } from '@reduxjs/toolkit';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import rootReducer from './state/reducers';
import { loadData } from './utils/storage'; // Utility to load persisted state
import ErrorPage from './components/errorpage';
import ShareChatDisplay from './components/publicshare';
// Load persisted state from local storage
const persistedState = loadData('chatAppState') || {};
// Create Redux store with persisted state using Redux Toolkit
const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedState
});
// Subscribe to store changes to persist state in local storage
store.subscribe(() => {
  const state = store.getState();
  localStorage.setItem('chatAppState', JSON.stringify(state));
});
const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    errorElement: <ErrorPage />,
  },
  {
    path: '/pollinate/:messageId',
    element: <ShareChatDisplay />,
    errorElement: <ErrorPage />,
  }
]);

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>,
);