/*
Component to display a list of categories.
Allows users to select and delete categories, enhancing user interaction within the chat application.
*/
import React, { useEffect, useState } from "react";
import "../styles/categorylist.css";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { MenuList, MenuListItem, Button, Window, WindowContent, Separator } from "react95";
import {
  clearCategories,
  clearMessages,
  clearTopics,
  deleteCategory,
  selectCategory,
  setCategoryKey,
  setNotificationState,
  setTopics,
} from "../state/actions";
function CategoryList({ handleTopicCreation, setStudyTabState }) {
  const dispatch = useDispatch();
  const books = useSelector((state) => state.books);
  const categories = useSelector((state) => state.categories);
  const selectedCategory = useSelector((state) => state.selectedCategory);
  const [activeCategory, setActiveCategory] = useState('');
  const [loadedCats, setLoadedCats] = useState([]);
  useEffect(() => {
    setLoadedCats(categories);
    setActiveCategory(selectedCategory);
  }, [categories, selectedCategory]);

  const handleDeleteCategory = (category, e) => {
    e.stopPropagation();
    dispatch(deleteCategory(category));
    if (category === selectCategory ?? categories.length === 0) {
      dispatch(clearTopics());
      dispatch(clearMessages());
      dispatch(selectCategory(""));
    }
  };

  const handleCategoryClick = (category) => {
    dispatch(setCategoryKey(category));
    if (books && books[category] && Object.keys(books[category]).length > 0) {
      dispatch(setTopics(books[category].topics));
    } else {
      dispatch(
        setNotificationState({
          level: "info",
          duration: 5,
          message: "Generating Topics...",
        })
      );
      handleTopicCreation(category);
    }
    dispatch(selectCategory(category));
    setStudyTabState({ activeStudyTab: 2 });
  };
  const handleResetCategories = () => {
    dispatch(clearCategories());
    dispatch(clearTopics());
    dispatch(selectCategory(""));
  };

  const defaultStyle = { margin: "10px", cursor: "pointer" };
  const selectedStyle = {
    margin: "10px",
    cursor: "pointer",
    backgroundColor: "darkBlue",
    color: "white",
    padding: "10px",
    fontWeight: "bold",
  };

  if (loadedCats.length === 0 ?? !activeCategory ?? activeCategory.length === 0) {
    return (
      <WindowContent className='notice-div'>
        <b>Please create new ideas in the Main menu!</b>
      </WindowContent>
    );
  }
  return (
    <Window>
      <WindowContent>
        <MenuList>
          {loadedCats.map((category, index) => (
            <MenuListItem
              key={index}
              style={
                activeCategory === category ? selectedStyle : defaultStyle
              }
              onClick={() => handleCategoryClick(category)}
            >
              <div className="category-item">
                {category}&emsp;
                <Button
                  className="delete-category-button"
                  onClick={(e) => handleDeleteCategory(category, e)}
                >
                  Delete
                </Button>
              <Separator />
              </div>
              <br />
            </MenuListItem>
          ))}
        </MenuList>
      </WindowContent>
      <Button onClick={(e) => handleResetCategories()}>Clear Categories</Button>
    </Window>
  );
}
CategoryList.propTypes = {
  handleTopicCreation: PropTypes.func.isRequired,
  setTabState: PropTypes.func.isRequired,
};
export default CategoryList;
