import { combineReducers } from 'redux';
const thinkingStateReducer = (state= false, action) => {
  switch(action.type) {
    case 'SET_THINKING_STATE':
      return action.payload
    default:
      return state;
  }
}
const loadingStateReducer = (state= '', action) => {
  switch(action.type) {
    case 'SET_LOADING_STATE':
      return action.payload
    default:
      return state;
  }
}
const notificationStateReducer = (state= '', action) => {
  switch(action.type) {
    case 'SET_NOTIFICATION_STATE':
      return action.payload
    default:
      return state;
  }
}
const apiKeyReducer = (state = '', action) => {
  switch(action.type) {
    case 'SET_API_KEY':
      return action.payload
    default:
      return state;
  }
}
const categoryKeyReducer = (state = '', action) => {
  switch(action.type) {
    case 'SET_CATEGORY_KEY':
      return action.payload;
    default:
      return state;
  }
};
const categoriesReducer = (state = [], action) => {
  switch(action.type) {
    case 'SET_CATEGORIES':
      return action.payload;
    case 'DELETE_CATEGORY':
      return state.filter(category => category !== action.payload);
    case 'CLEAR_CATEGORIES':
        return [];
    default:
      return state;
  }
};
const selectedCategoryReducer = (state = null, action) => {
  switch(action.type) {
    case 'SELECT_CATEGORY':
      return action.payload;
    default:
      return state;
  }
};
const booksReducer = (state = {}, action) => {
  switch(action.type) {
    case 'SET_BOOKS':
      return action.payload;
    default:
      return state;
  }
};
const selectedBookReducer = (state = null, action) => {
  switch(action.type) {
    case 'SELECT_BOOK':
      return action.payload;
    default:
      return state;
  }
};
const topicsReducer = (state = [], action) => {
  switch(action.type) {
    case 'SET_TOPICS':
      return action.payload;
    case 'CLEAR_TOPICS':
        return [];
    default:
      return state;
  }
};
const selectedTopicReducer = (state = null, action) => {
  switch(action.type) {
    case 'SELECT_TOPIC':
      return action.payload;
    default:
      return state;
  }
};
const chatHistoryReducer = (state = [], action) => {
  switch(action.type) {
    case 'ADD_CHAT_MESSAGE':
      return [...state, action.payload];
    case 'SET_CHAT_MESSAGE':
      return [...action.payload]
    case 'REMOVE_LAST_MESSAGE':
      return state.slice(0,-1);
    case 'REMOVE_CHAT_MESSAGE':
      return state.slice(0,-2);
    case 'CLEAR_MESSAGES':
      return [];
    case 'RESET_CONVERSATION':
      return [];
    default:
      return state;
  }
};
const userSettingsReducer = (state = { responseLength: 100, readabilityScore: 50, speakerStyle: 'explain-like-im-five', imageModel: 'schnell', useImageModel: true }, action) => {
  switch(action.type) {
    case 'SET_BIOGRAPHY':
      return { ...state, biography: action.payload };
    case 'SET_RESPONSE_LENGTH':
      return { ...state, responseLength: action.payload };
    case 'SET_READABILITY_SCORE':
      return { ...state, readabilityScore: action.payload };
    case 'SET_SPEAKER_STYLE':
      return { ...state, speakerStyle: action.payload };
    case 'USE_IMAGE_MODEL':
      return { ...state, useImageModel: action.payload };
    case 'SET_IMAGE_MODEL':
      return { ...state, imageModel: action.payload };
    default:
      return state;
  }
};
const privateUserReducer = (state=false, action) => {
  switch(action.type) {
    case "SET_PRIVACY":
      return action.payload;
    default:
      return state
  }
};
const sharedLinkReducer = (state=[], action) => {
  switch(action.type) {
    case "SET_LINK":
      return [...state, action.payload];
    case "UPDATE_LINKS":
      return [...action.payload];
    default:
      return state
  }
};
export default combineReducers({
  thinking: thinkingStateReducer,
  notifications: notificationStateReducer,
  loadingState: loadingStateReducer,
  apiKey: apiKeyReducer,
  categoryKey: categoryKeyReducer,
  categories: categoriesReducer,
  selectedCategory: selectedCategoryReducer,
  books: booksReducer,
  selectedBook: selectedBookReducer,
  topics: topicsReducer,
  selectedTopic: selectedTopicReducer,
  chatHistory: chatHistoryReducer,
  userSettings: userSettingsReducer,
  privateUser: privateUserReducer,
  sharedLinks: sharedLinkReducer
});