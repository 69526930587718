/*
Component to display suggested conversation topics.
Provides a list of topics that users can select to further explore conversational content, including re-sending categories for new topics.
*/
import React, { useEffect, useState } from "react";
import "../styles/topiclist.css";
import PropTypes from "prop-types";
import {
  Button,
  WindowHeader,
  Window,
  WindowContent,
  Hourglass,
  Separator,
} from "react95";
import { useDispatch, useSelector } from "react-redux";
import {
  setBooks,
  setTopics,
  selectTopic,
  addChatMessage,
  setChatMessage,
  setLoadingState,
  setThinkingState,
  setNotificationState,
} from "../state/actions";
import useAPI from "../utils/api";
import ImageHandler from "../utils/imagehandler";
import { getCurrentDateString } from "../utils/tools";
import { handleDownloads3, handleTextUploads3 } from "../utils/s3crud";
function TopicList({ setWindowOpen }) {
  const api = useAPI();
  const imagehandler = new ImageHandler();
  const dispatch = useDispatch();

  const userSettings = useSelector((state) => state.userSettings);
  const categoryKey = useSelector((state) => state.categoryKey);
  const topics = useSelector((state) => state.topics);
  const books = useSelector((state) => state.books);
  const selectedTopic = useSelector((state) => state.selectedTopic);
  const selectedCategory = useSelector((state) => state.selectedCategory);
  const privateUser = useSelector((state) => state.privateUser);

  const [activeTopic, setActiveTopic] = useState("");
  const [activeCategory, setActiveCategory] = useState("");
  const [loadedTopics, setLoadedTopics] = useState([]);
  const [currentSettings, setCurrentSettings] = useState({});
  const loading = useSelector((state) => state.loadingState);

  useEffect(() => {
    setLoadedTopics(topics);
    setActiveCategory(selectedCategory);
    setActiveTopic(selectedTopic);
    setCurrentSettings(userSettings);
  }, [topics, selectedCategory, selectedTopic, userSettings]);

  const setLoading = (value) => {
    dispatch(setLoadingState(value));
  };

  const saveUpdateHistory = (updatedChatHistory, response) => {
    const newChatHistory = [
      ...updatedChatHistory,
      { role: "assistant", content: response },
    ];
    dispatch(addChatMessage({ role: "assistant", content: response }));
    const updatedBooks = {
      ...books,
      [categoryKey]: {
        ...books[categoryKey],
        [activeTopic]: newChatHistory,
      },
    };
    dispatch(setBooks(updatedBooks));
  };

  const handleTopicsReset = async () => {
    setLoading(true);
    try {
      const userInput = books[categoryKey].input;
      const prompt = api.formatTopicTemplate();
      const messages = [
        { role: "system", content: prompt },
        { role: "user", content: userInput },
      ];
      const response = await api.sendChatRequest(messages);
      const newTopics = response
        .split("\n")
        .filter((line) => line.trim() !== "");
      const newBooks = {
        ...books,
        [categoryKey]: {
          input: userInput,
          topics: newTopics,
        },
      };
      dispatch(setBooks(newBooks));
      dispatch(setTopics(newTopics));
    } catch (error) {
      console.error("Error handling user input:", error);
    } finally {
      setLoading(false);
    }
  };

  const saveTrainingData = async (updatedChatHistory, response) => {
    const getData = async () => {
      try {
        const currentDate = getCurrentDateString(); // Moved this line to be inside the function
        const text = await handleDownloads3(`${currentDate}/data.txt`);
        const historyBooks = JSON.parse(text);
        return historyBooks;
      } catch (error) {
        return {};
      }
    };
  
    try {
      const historyBooks = await getData();
  
      if (!privateUser) {
        const newChatHistory = [
          ...updatedChatHistory,
          { role: "assistant", content: response }, // Corrected object syntax
        ];
  
        const updatedBooks = {
          ...historyBooks,
          [categoryKey]: {
            ...historyBooks[categoryKey],
            [selectedTopic]: newChatHistory,
          },
        };

        const currentDate = getCurrentDateString(); // Ensure this is defined in the correct scope
        await handleTextUploads3(JSON.stringify(updatedBooks), `${currentDate}/data.txt`);
      }
    } catch (error) {
      console.log('An error occurred:', error);
    }
  };
  const handleTopicSelection = async (topic) => {
    setLoading(true);
    dispatch(setThinkingState(true));
    dispatch(selectTopic(topic));
    setWindowOpen(false);
    if (
      topic != null &&
      books &&
      books[categoryKey] &&
      books[categoryKey][topic] &&
      books[categoryKey][topic].length > 1 &&
      books[categoryKey][topic][1].content !==
        "Error sending chat request: Please provide a valid OpenAI API key"
    ) {
      dispatch(setChatMessage(books[categoryKey][topic]));
    } else {
      console.log(currentSettings);
      dispatch(
        setNotificationState({
          level: "info",
          duration: 3,
          message: "Generating Intro Chat...",
        })
      );
      try {
        dispatch(setChatMessage([]));
        const chatHistory = { role: "user", content: topic };
        dispatch(addChatMessage(chatHistory));
        const prompt = api.formatChatTemplate(
          topic,
          activeCategory,
          currentSettings.responseLength,
          currentSettings.readabilityScore,
          currentSettings.speakerStyle,
          currentSettings.useImageModel,
          currentSettings.biography
        );
        const messages = [
          { role: "system", content: prompt },
          {
            role: "user",
            content: "Respond with an introduction to the topic and category.",
          },
        ];
        console.log(messages);
        const response = await api.sendChatRequest(messages);
        console.log(response);
        if (response === null) {
          setLoading(false);
          dispatch(setThinkingState(false));
          return;
        }
        const imagePrompts = response.match(/<IMAGE_PROMPT>\s*(.*?)\s*<\/IMAGE_PROMPT>/gs);
        if (imagePrompts && imagePrompts.length > 0) {
          try {
            const imagePromises = imagePrompts.map(async (prompt) => {
              const promptContent = prompt.match(
                /<IMAGE_PROMPT>\s*(.*?)\s*<\/IMAGE_PROMPT>/s
              )[1];
              const fetchedImageUrl = await imagehandler.generateImage(
                promptContent,
                `https://api-inference.huggingface.co/models/black-forest-labs/FLUX.1-${userSettings.imageModel ?? 'schnell'}`
              );

              return {
                prompt: prompt,
                markdownImageUrl: `![${promptContent.replace(/ |\n/g, "::").replace(/ /g, "**")}](${fetchedImageUrl})`,
              };
            });

            Promise.all(imagePromises).then(async(results) => {
              let modifiedResponse = response;
              results.forEach(({ prompt, markdownImageUrl }) => {
                modifiedResponse = modifiedResponse.replace(
                  prompt,
                  markdownImageUrl
                );
              });
              await saveTrainingData(messages, modifiedResponse.replace(/!\[(.*?)\]\((.*?)\)/g, ""));
              saveUpdateHistory(chatHistory, modifiedResponse);
              dispatch(setThinkingState(false));
            });
          } catch {
            await saveTrainingData(messages, response);
            saveUpdateHistory(
              [{ role: "assistant", content: response }],
              response
            );
            dispatch(setThinkingState(false));
          }
        } else {
          await saveTrainingData(messages, response);
          saveUpdateHistory(
            [{ role: "assistant", content: response }],
            response
          );
          dispatch(setThinkingState(false));
        }
      } catch (error) {
        console.error("Error handling topic selection:", error);
      }
    }
    setLoading(false);
    dispatch(setThinkingState(false));
  };
  const defaultStyle = { margin: "10px", cursor: "pointer" };
  const selectedStyle = {
    margin: "10px",
    cursor: "pointer",
    backgroundColor: "darkBlue",
    color: "white",
    padding: "10px",
    fontWeight: "bold",
  };
  if (loading) {
    return (
      <WindowContent>
        <div
          style={{
            margin: "20px",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            flex: "auto",
          }}
        >
          <Hourglass />
        </div>
      </WindowContent>
    );
  }

  if (!activeCategory ?? loadedTopics.length === 0) {
    return (
      <WindowContent className='notice-div'>
        <b>
          Please create new ideas in the Main menu, or select a category from
          the Ideas menu!
        </b>
      </WindowContent>
    );
  }
  return (
    <Window>
      {loading ? (
        <WindowContent>
          <div style={{ alignContent: "center", alignItems: "center" }}>
            <Hourglass />
          </div>
        </WindowContent>
      ) : (
        <WindowContent>
          <WindowHeader>
            Topics in {activeCategory && activeCategory.slice(0, 15)}...
          </WindowHeader>
          <div className="topic-list">
            {loadedTopics.map((topic, index) => (
              <div
                className={`topic-item ${
                  activeCategory === topic ? "selected" : ""
                }`}
                key={index}
                active={toString(activeTopic === topic)}
                onClick={(e) => handleTopicSelection(topic)}
                style={activeTopic === topic ? selectedStyle : defaultStyle}
              >
                {topic}
                <br />
                <br />
                <Separator />
              </div>
            ))}
          </div>
          <Button onClick={() => handleTopicsReset()}>Regenerate Topics</Button>
        </WindowContent>
      )}
    </Window>
  );
}
TopicList.propTypes = {
  setWindowOpen: PropTypes.func.isRequired,
};
export default TopicList;
